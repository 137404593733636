import type { ReactElement } from 'react'
import type { LayoutProps } from './HeaderComponents/PageLayoutAndTracking'
import {
  PostTrialApplyLayout,
  TrialApplicationLayout,
} from './PatientLayouts.client'

export default function getParamsProvidedLayout(page: ReactElement) {
  return <TrialApplicationLayout>{page}</TrialApplicationLayout>
}

export function getParamsProvidedLayoutWithLayoutProps(props: LayoutProps) {
  return function Component(page: ReactElement) {
    return <TrialApplicationLayout {...props}>{page}</TrialApplicationLayout>
  }
}

export function getPostTrialApplyParamsProvidedLayoutWithLayoutProps(
  props: LayoutProps,
) {
  return function Component(page: ReactElement) {
    return <PostTrialApplyLayout {...props}>{page}</PostTrialApplyLayout>
  }
}
